import api from "./api";
import config from "./config";

const token = () => {
  return api.post(config.token, {
    telegramData: {
      chat_id: window.Telegram.WebApp.initDataUnsafe.user ? String(window.Telegram.WebApp.initDataUnsafe.chat_instance) : "559067620",
      user_id: window.Telegram.WebApp.initDataUnsafe.user ? String(window.Telegram.WebApp.initDataUnsafe.user.id) : "557597983"
    }
  });
};

export default token;
