import categories from "@/api/categories";
import hobbiesList from "@/api/hobby";
import profile from "@/api/profile";
import { createStore } from 'vuex'


const store = createStore({
    state: {
      profileStatus: "0",
      categories: [],
      hobbies: [],
      profile: {}
    },
    mutations: {
        SET_CATEGORIES: (state, categories) => {
            state.categories = categories;
        },
        SET_HOBBIES: (state, hobbies) => {
            state.hobbies = hobbies;
        },
        SET_PROFILE: (state, profile) => {
          state.profile = profile;
        },
    },
    actions: {
        async GET_CATEGORIES({ commit }) {
            return await categories.categoriesList()
              .then((list) => {
                  commit("SET_CATEGORIES", list.data.categories);
                  return list.data.categories
              })
              .catch((error) => {
                  console.log(error);
                  return {};
              });
        },
        async GET_HOBBIES({ commit }) {
            return hobbiesList()
              .then((list) => {
                  commit("SET_HOBBIES", list.data.hobbies);
                  return list.data.hobbies
              })
              .catch((error) => {
                  console.log(error);
                  return {};
              });
        },
      async GET_PROFILE({ commit }) {
        return profile.profile()
          .then((list) => {
            commit("SET_PROFILE", list.data.user);
            return list.data.user
          })
          .catch((error) => {
            console.log(error);
            return {};
          });
      },
    },
    getters: {
        CATEGORIES(state) {
            return state.categories;
        },
        HOBBIES(state) {
            return state.hobbies;
        },
        PROFILE(state) {
          return state.profile;
        },
    },
})

export default store;