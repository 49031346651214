import api from "./api";
import config from "./config";
import token from "@/api/token";

const categoriesList = () => {
  return api.get(config.categoriesList);
};

const categoryUsers = (id, page = 1, per_page = 10) => {
  return token().then((token)=> {
    api.defaults.headers.Authorization = `Bearer ${token.data.token}`;
    return api.get(`/api/v1/category/${id}/get-users?lang=ru&page=${page}&per_page=${per_page}`);
  })
};

export default {
  categoriesList,
  categoryUsers
};
