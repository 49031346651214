import api from "./api";
import config from "./config";
import token from "@/api/token";

const profile = () => {
  return token().then((token)=> {
    api.defaults.headers.Authorization = `Bearer ${token.data.token}`;
    return api.get(config.profile);
  })
};

const userProfile = (id) => {
  return token().then((token)=> {
    api.defaults.headers.Authorization = `Bearer ${token.data.token}`;
    return api.get(config.profile + `/${id}`);
  })
};

const editProfile = (body) => {
  return token().then((token)=> {
    api.defaults.headers.Authorization = `Bearer ${token.data.token}`;
    return api.post(config.editProfile, body);
  })
};

const limit = () => {
  return token().then((token)=> {
    api.defaults.headers.Authorization = `Bearer ${token.data.token}`;
    return api.post(config.limit);
  })
};

export default {
  profile,
  userProfile,
  editProfile,
  limit
};
